export const getGlobalObject = () => {
  if (typeof window.MyAccount === "undefined") {
    window.MyAccount = {};
  }

  return window.MyAccount;
};

export const globalObject = getGlobalObject();

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
