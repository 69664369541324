export const Vendors = {
  AlbelliBe: "albelli.be",
  AlbelliDe: "albelli.de",
  AlbelliNl: "albelli.nl",
  BonusPrint: "bonusprint.co.uk",
  FotoKnudsen: "fotoknudsen.no",
  MonAlbumPhoto: "monalbumphoto.fr",
  Onskefoto: "onskefoto.se",
  PosterxxlDE: "posterxxl.de",
  PosterxxlAT: "posterxxl.at",
  B2BPosterxxlDE: "b2b.posterxxl.de",
  PBXUK: "photobox.co.uk",
  PBXFR: "photobox.fr",
  PBXIE: "photobox.ie",
  PBXIT: "photobox.it",
  PBXDK: "photobox.dk",
  HFMES: "hofmann.es",
  HFMPT: "hofmann.pt"
};

const VendorCountryCode = {
  "albelli.be": "BE",
  "albelli.de": "DE",
  "albelli.nl": "NL",
  "bonusprint.co.uk": "GB",
  "fotoknudsen.no": "NO",
  "monalbumphoto.fr": "FR",
  "onskefoto.se": "SE",
  "posterxxl.de": "DE",
  "posterxxl.at": "AT",
  "b2b.posterxxl.de": "DE",
  "photobox.co.uk": "GB",
  "photobox.fr": "FR",
  "photobox.ie": "IE",
  "photobox.it": "IT",
  "photobox.dk": "DK",
  "hofmann.es": "ES",
  "hofmann.pt": "PT"
};

export const getVendor = (url) => {
  const split = url.split(".");
  const l = split.length;
  //if running at localhost we need a default
  if (l < 2) return Vendors.AlbelliNl; //change here for localhost testing

  //3 part vendors name hack
  //check for (-2) the co in bonusprint.co.uk, or (-3) the b2b in b2b.posterxxl.de
  //check these are too short i.e. are not "myaccount" or "bonusprint"
  if (split[l - 2].length <= 3 || split[l - 3].length <= 3) return `${split[l - 3]}.${split[l - 2]}.${split[l - 1]}`;
  return `${split[l - 2]}.${split[l - 1]}`;
};

export const getCurrentVendor = () => getVendor(window.location.hostname);

export const mapToMerchandiseVendors = (vendor) => {
  // onskefoto.se is called albelli.se in Merchandise API
  return vendor === Vendors.Onskefoto ? "albelli.se" : vendor;
};

export const mapToRealVendors = (vendor) => {
  // onskefoto.se is called albelli.se in Merchandise API
  return vendor === "albelli.se" ? Vendors.Onskefoto : vendor;
};

export const getVendorName = () => {
  const entireVendor = getVendor(window.location.hostname);

  return entireVendor.split(".")[0];
};

export const vendor = getVendor(window.location.hostname);

export const vendorCountryCode = VendorCountryCode[getVendor(window.location.hostname)];
